<template>
    <div>
        <div class="columns is-mobile is-multiline">
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Name') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               :placeholder="i18n('Name')" v-model="bookMagazine.name">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('State') }}</label>
                    <div class="control">
                        <input type="checkbox" v-model="isActive" @click="toggleCheckboxes">
                        {{ i18n(isActive ? 'Active' : 'Inactive') }}
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Select Program') }}</label>
                    <vue-select :source="route('programs.options')"
                                v-model="bookMagazine.program"
                                :http="http"
                                track-by="name"/>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Property') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               :placeholder="i18n('UserProperty')"
                               v-model="bookMagazine.userProperty">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Segmentation') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               :placeholder="i18n('Segmentation')"
                               v-model="bookMagazine.segmentation">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Position') }}</label>
                    <div class="control">
                        <input class="input" type="number"
                               :placeholder="i18n('Position')" v-model="bookMagazine.position">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Choose an File') }}</label>
                    <div class="control">
                        <label class="file-label">
                            <input class="file-input" type="file"
                                   accept=".pdf" @change="handleFileUpload">
                            <span class="file-cta">
                                <span class="file-label">
                                    {{ i18n('Choose an File') }}...
                                </span>
                            </span>
                            <span class="file-name">
                                {{ fileName || i18n('No file chosen') }}
                            </span>
                        </label>
                        <p class="letterFileSize">
                            {{ i18n('Maximum file size') }}: 18 MB.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <a class="is-full-mobile is-half-tablet button-alignment"
           @click="$router.push('/bookMagazine')">
            <button class="button butt-return">
                {{ i18n('Back') }}
                <fa class="i-icon" icon="arrow-left"/>
            </button>
        </a>
        <a class="is-full-mobile is-half-tablet button-alignment"
           @click="fetchUpdate">
            <button class="button butt-view">
                {{ i18n('Update') }}
                <fa class="i-icon" icon="check"/>
            </button>
        </a>
        <a class="is-full-mobile is-half-tablet button-alignment"
           @click="fetchDelete">
            <button class="button butt-delete">
                {{ i18n('Delete') }}
                <fa class="i-icon" icon="trash-alt"/>
            </button>
        </a>
    </div>
</template>

<script>
import { FontAwesomeIcon as Fa } from '@fortawesome/vue-fontawesome';
import { VueSelect } from '@enso-ui/select/bulma';

export default {
    name: 'Edit',

    components: { Fa, VueSelect },

    inject: ['http', 'i18n', 'errorHandler', 'route'],

    data: () => ({
        bookMagazine: {},
        isActive: false,
        programs: {},
        fileName: '',
    }),

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.http.get(this.route('bookMagazine.show', this.$route.params.bookMagazine))
                .then(({ data }) => {
                    this.bookMagazine = data.bookMagazine;
                    this.isActive = data.bookMagazine.state === 'Active';
                }).catch(this.errorHandler);
        },
        fetchDelete() {
            this.http.delete(this.route('bookMagazine.destroy', this.$route.params.bookMagazine))
                .then(() => {
                    this.$router.push('/bookMagazine');
                }).catch(this.errorHandler);
        },
        handleFileUpload(event) {
            // eslint-disable-next-line prefer-destructuring
            this.bookMagazine.file = event.target.files[0];
            this.fileName = event.target.files[0].name;
        },
        fetchUpdate() {
            const formData = new FormData();
            formData.append('name', this.bookMagazine.name);
            formData.append('file', this.bookMagazine.file);
            formData.append('id', this.bookMagazine.id);
            formData.append('state', this.isActive ? 'Active' : 'Inactive');
            formData.append('program', this.bookMagazine.program);
            formData.append('userProperty', this.bookMagazine.userProperty);
            formData.append('segmentation', this.bookMagazine.segmentation);
            formData.append('position', this.bookMagazine.position);
            const headers = { 'Content-Type': 'multipart/form-data' };
            this.http.post(this.route('bookMagazine.updateFile'), formData,
                { headers })
                .then(() => {
                    this.$router.push('/bookMagazine');
                }).catch(this.errorHandler);
        },
        toggleCheckboxes() {
            this.isActive = !this.bookMagazine.state === 'Active';
            this.bookMagazine.state = !this.bookMagazine.state === 'Active' ? 'Active' : 'Inactive';
        },
    },
};
</script>

<style lang="scss">
@import '../../../sass/enso.scss';

.butt-return{
    background-color: $orange;
    border-color: transparent;
    color: $secondary !important;
}

.i-icon {
    margin-left: 5px;
}

.butt-delete{
    background-color: $red;
    border-color: transparent;
    color: $secondary !important;
}

.butt-view{
    background-color: $green;
    border-color: transparent;
    color: $secondary !important;
}

.button-alignment{
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 40px;
}

.styleInput{
    background-color: transparent;
    border-color: transparent;
}

.letterFileSize{
    font-size: small;
    color: $greyToneEleven;
}

</style>
